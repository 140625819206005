.contact_wrapper {
    .contact_title_wrapper {
        background: var(--Primary-Dark, #0A2540);
        padding-top: 80px;
        padding-bottom: 132px;

        h1 {
            color: var(--generic-white, #FFF);
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: -0.36px;
        }

        p {
            color: var(--gray-100, #F1F5F9);
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: -0.18px;
        }
    }

    .form_wrapper {
        border-radius: 8px;
        background: #FFF;
        box-shadow: 0px 20px 25px -5px rgba(15, 23, 42, 0.10), 0px 10px 10px 0px rgba(15, 23, 42, 0.05);

        label {
            color: var(--gray-900, #0F172A);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
            display: block;
        }

        input {
            color: #0F172A;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.16px;
            padding: 11px 15px;
            border-radius: 10px;
            border: 1px solid var(--gray-300, #CBD5E1);
            background: var(--generic-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
            width: 100%;
        }

        textarea {
            padding: 11px 15px;
            border-radius: 10px;
            border: 1px solid var(--gray-300, #CBD5E1);
            background: var(--generic-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
            width: 100%;
            height: 76px;
            resize: none;
        }

        .react-tel-input {
            input {
                padding-left: 46px;
                width: 100%;
                height: 46px;
            }
        }

        .error_div_gray {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #6e7280;
            margin-top: 6px;
            width: 100%;
        }

        .error_div_red {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #ff5656;
            margin-top: 6px;
            width: 100%;
        }

        button {
            color: var(--generic-white, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
            padding: 13px 20px;
            border-radius: 10px;
            background: var(--primary-600, #1302FF);
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
            width: 100%;
        }

        label {
            color: var(--gray-900, #0F172A);
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
            display: block;
        }

        input {
            color: #0F172A;
            font-family: Inter;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: -0.16px;
            padding: 11px 15px;
            border-radius: 10px;
            border: 1px solid var(--gray-300, #CBD5E1);
            background: var(--generic-white, #FFF);
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
            width: 100%;
        }

        .error_div_gray {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #6e7280;
            margin-top: 6px;
            width: 100%;
        }

        .error_div_red {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #ff5656;
            margin-top: 6px;
            width: 100%;
        }

        button {
            color: var(--generic-white, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
            padding: 13px 20px;
            border-radius: 10px;
            background: var(--primary-600, #1302FF);
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
            width: 100%;
        }
    }

    .love_hear_from {
        h2 {
            color: var(--gray-900, #0F172A);
            text-align: center;
            font-family: Inter;
            font-size: 30px;
            font-style: normal;
            font-weight: 700;
            line-height: 38px;
            letter-spacing: -0.36px;
        }

        h4 {
            color: var(--gray-600, #475569);
            text-align: center;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: -0.18px;
        }

        .contact_card {
            padding: 20px;
            border-radius: 16px;
            background: var(--gray-50, #F8FAFC);

            h1 {
                color: var(--gray-900, #0F172A);
                text-align: center;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 28px;
                letter-spacing: -0.2px;
            }

            p {
                color: var(--gray-500, #64748B);
                text-align: center;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.16px;
            }
        }
    }
}

@media screen and (max-width: 1536px) {
    .contact_wrapper {
        .contact_title_wrapper {
            padding-top: 56px;
            padding-bottom: 132px;

            h1 {
                font-size: 30px;
                line-height: 40px;
            }

            p {
                font-size: 16px;
                line-height: 22px;
            }
        }



        .love_hear_from {
            h2 {
                font-size: 28px;
                font-weight: 600;
                line-height: 38px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .contact_wrapper {
        .contact_title_wrapper {
            padding-top: 56px;
            padding-bottom: 132px;

            h1 {
                font-size: 30px;
                line-height: 40px;
            }

            p {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .form_wrapper {
            border-radius: 0px;
            background: #FFF;
            box-shadow: none;

            label {
                color: var(--gray-900, #0F172A);
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 22px;
                letter-spacing: -0.16px;
                display: block;
            }

            input {
                color: #0F172A;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                letter-spacing: -0.16px;
                padding: 11px 15px;
                border-radius: 10px;
                border: 1px solid var(--gray-300, #CBD5E1);
                background: var(--generic-white, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
                width: 100%;
            }

            textarea {
                padding: 11px 15px;
                border-radius: 10px;
                border: 1px solid var(--gray-300, #CBD5E1);
                background: var(--generic-white, #FFF);
                box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
                width: 100%;
                height: 76px;
                resize: none;
            }
        }

        .love_hear_from {
            .contact_card {
                padding: 20px;
                border-radius: 16px;
                background: var(--gray-50, #F8FAFC);

                h1 {
                    color: var(--gray-900, #0F172A);
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 28px;
                    letter-spacing: -0.2px;
                }

                p {
                    color: var(--gray-500, #64748B);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: -0.16px;
                }
            }
        }
    }
}