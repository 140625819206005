 .footer_wrapper {
   background: #0A2540;
   padding-top: 80px;

   .incredible_together {
     margin-bottom: 80px;
     padding: 77px 71px;
     border-radius: 60px;
     background: rgba(255, 255, 255, 0.10);

     h1 {
       color: #FFF;
       //  text-align: center;
       font-size: 50px;
       font-style: normal;
       font-weight: 600;
       line-height: 60px;
       //  text-transform: uppercase;
     }

     p {
       color: #FFF;
       font-size: 18px;
       font-style: normal;
       font-weight: 500;
       line-height: 30px;
     }

     .book_demo_btn {
       color: var(--generic-white, #FFF);
       font-size: 16px;
       font-style: normal;
       font-weight: 500;
       line-height: 22px;
       letter-spacing: -0.16px;
       padding: 13px 20px;
       border-radius: 8px;
       background: var(--primary-600, #4F46E5);
       box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
     }

   }

   .nav_link {
     color: var(--base-white, #FFF);
     font-family: Inter;
     font-size: 16px;
     font-style: normal;
     font-weight: 400;
     line-height: 22px;
     letter-spacing: -0.16px;
   }

   .all_right_reserved {
     border-top: 1px solid var(--gray-600, #475467);
     padding: 24px 0;
     display: flex;
     justify-content: space-between;
     align-items: center;

     .copy_right {
       color: var(--base-white, #FFF);
       font-family: Inter;
       font-size: 16px;
       font-style: normal;
       font-weight: 400;
       line-height: 22px;
       letter-spacing: -0.16px;
     }
   }
 }

 @media screen and (max-width: 1536px) {
   .footer_wrapper {
     padding-top: 60px;

     .incredible_together {
       margin-bottom: 40px;
       padding: 40px 20px;
       border-radius: 24px;

       h1 {
         font-size: 30px;
         line-height: 44px;
         text-transform: none;
       }

       p {
         font-size: 18px;
         line-height: 24px;
       }
     }

     .all_right_reserved {
       justify-content: start;
       align-items: start;
       flex-direction: column;
       gap: 24px;
     }
   }
 }

 @media screen and (max-width: 768px) {
   .footer_wrapper {
     padding-top: 40px;

     .incredible_together {
       margin-bottom: 40px;
       padding: 40px 20px;
       border-radius: 24px;

       h1 {
         font-size: 28px;
         line-height: 44px;
         text-transform: none;
       }

       p {
         font-size: 16px;
         line-height: 24px;
       }
     }

     .all_right_reserved {
       justify-content: start;
       align-items: start;
       flex-direction: column;
       gap: 24px;

       .copy_right {
         color: var(--base-white, #FFF);
         font-family: Inter;
         font-size: 16px;
         font-style: normal;
         font-weight: 400;
         line-height: 22px;
         letter-spacing: -0.16px;
       }
     }
   }
 }