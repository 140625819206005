.header_wrapper {
    height: 69px;

    .public_header {
        background-color: #0A2540;
        border-bottom: 1px solid var(--gray-700, #344054);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 2;
        // box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 100%;
        height: fit-content;

        .nav_link {
            color: var(--neutral-200, #E5E5E5);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
        }

        // ****
        .more_button_list {
            // width: fit-content;
            padding: 6px;
            // height: fit-content;
            background: #ffffff;
            border: 1px solid #eaecf0;
            box-shadow: 0px 24px 48px -12px rgba(16, 24, 40, 0.18);
            border-radius: 8px;
            position: absolute;
            z-index: 1;
            display: none;
            top: 30px;
            left: 50%;


            .more_button_list_item {
                padding: 6px 10px;
                border-radius: 4px;
                cursor: pointer;

                a {
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    letter-spacing: -0.16px;
                    color: #101828;
                    white-space: nowrap;
                }

                &:hover {
                    background: #dfe0e2;
                    border-radius: 6px;
                }
            }

            .more_button_list_item:last-child:after {
                display: none;
            }
        }

        .more_button_list.active {
            display: block;
            // transform: translate(0, 0) scale(1);
            transform: translate(-50%, 0%);
        }

        // ***

        .book_demo_btn {
            color: var(--generic-white, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: -0.16px;
            padding: 11px 16px;
            border-radius: 8px;
            background: #4F46E5;
            box-shadow: 0px 1px 2px 0px rgba(15, 23, 42, 0.06);
        }
    }
}

@media screen and (max-width: 768px) {
    .header_wrapper {
        height: 56px;
    }
}