@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 1280px;
  width: 100%;
  padding: 0 16px;
  margin: auto;
}

.react-tel-input input {
  padding-left: 46px !important;
  width: 100%;
  height: 46px !important;
}

.react-tel-input .flag-dropdown {
  background-color: #fff !important;
  border-radius: 10px 0 0 10px !important;
}

.react-tel-input .selected-flag {
  border-radius: 10px 0 0 10px;
  background-color: transparent !important;
}
